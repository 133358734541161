// 'Arrays.asList("' + shopifyFields.map(t=> t.dataField).join('", "') + '")'
const shopifyFields = [
  {label: "Shopify Filtered (US & CA & EU)", dataField: "shopifyfiltered"},
  {
    label: "Shopify Filtered (US & CA & EU) Extra Webhook IMP: (Please set a different hook in the same channel)",
    dataField: "shopifyfiltered2"
  },

  {label: "Shopify Filtered US", dataField: "shopifyfilteredus"},
  {
    label: "Shopify Filtered US Extra Webhook IMP: (Please set a different hook in the same channel)",
    dataField: "shopifyfilteredus2"
  },
  {label: "Shopify Filtered EU", dataField: "shopifyfilteredeu"},
  {label: "Shopify Filtered EU Extra Webhook IMP: (Please set a different hook in the same channel)", dataField: "shopifyfilteredeu2"},

  {label: "Shopify Filtered CA", dataField: "shopifyfilteredca"},
  {label: "Shopify Filtered AU", dataField: "shopifyfilteredau"},
  {label: "Shopify Filtered NZ", dataField: "shopifyfilterednz"},
  {label: "Shopify Filtered MX", dataField: "shopifyfilteredmx"},
  {label: "Shopify Filtered PH", dataField: "shopifyfilteredph"},
  {label: "Shopify Casio", dataField: "shopify_casio_uk"},
    {label: "Naked", dataField: "naked"},
  {label: "Shopify Filtered Asia", dataField: "shopifyfilteredasia"},
    {label: "Shopify Filtered Asia Extra Webhook IMP: (Please set a different hook in the same channel)", dataField: "shopifyfilteredasia2"},
  {label: "Shopify Hats", dataField: "hatclub"},
  {
    label: "Shopify Hats Extra Webhook IMP: (Please set a different hook in the same channel)",
    dataField: "hatclub2"
  },
  {label: "Shopify Collectibles", dataField: "shopifycollectibles"},
      {label: "Shop App Exclusive (Shop App Only Products)", dataField: "shopApp"},
      {label: "Shop App Unfiltered", dataField: "shopAppUnfiltered"},
  {label: "Shopify Unfiltered", dataField: "unfiltered"},
  {label: "Shopify Unfiltered  Extra Webhook IMP: (Please set a different hook in the same channel)", dataField: "unfiltered2"},
  {label: "Shopify EU+UK Unfiltered", dataField: "shopify_ukeu_unfiltered"},
  {label: "Shopify NZ Unfiltered", dataField: "shopify_nz_unfiltered"},
  {label: "Shopify Filtered No Stock", dataField: "shopifynostock"},
  {
    label: "Shopify Filtered Variants",
    dataField: "shopifyfilteredvariants"
  },
      {label: "Shopify Queue", dataField: "shopifyqueue"},
  {label: "Shopify Pages/Blogs", dataField: "shopifypagesblogs"},
  {label: "Shopify Pages/Blogs (Asia Only)", dataField: "shopifypagesblogs_asia"},
  {label: "Shopify Raffles", dataField: "shopifyraffles"},
  {label: "Shopify UK Raffles", dataField: "shopify_uk_raffles"},
  {label: "Shopify Pokemon", dataField: "shopify_pokemon"},
  {label: "Shopify One Piece", dataField: "shopify_onepiece"},
  {label: "Shopify Pokemon US", dataField: "shopify_pokemon_us"},
  {label: "Shopify Pokemon AU", dataField: "shopify_pokemon_au"},
  {label: "Shopify Vinyl", dataField: "shopify_vinyl"},
        {label: "Shopify Antibot & Checkpoint", dataField: "antibot"},
        {label: "Shopify Questions", dataField: "shopifyquestion"},
        {label: "Popmart AU Shopify", dataField: "popmart_au_shopify"},
        {label: "Popmart NZ Shopify", dataField: "popmart_nz_shopify"},
        {label: "Popmart SG Shopify", dataField: "popmart_sg_shopify"},
    {label: "MCT Tokyo", dataField: "mcttokyo"},
      {label: "Denim Tears", dataField: "denimtears"},
      {label: "Mattel Creations", dataField: "mattel"},
   {label: "Shopify Golf", dataField: "golfshopify"},
     {label: "Funko EU", dataField: "funkoeu"},
  {label: "Shopify Discount", dataField: "shopifydiscount"},

  {label: "DSM E-Shop (Shop.dover..., etc)", dataField: "dsmeshop"},
  {label: "DSM E-Shop  (Shop.dover..., etc) Extra Webhook IMP: (Please set a different hook in the same channel)", dataField: "dsmeshop2"},
  {label: "Undefeated", dataField: "undftd"},
  {label: "Feature", dataField: "feature_shopify"},
  {label: "Pdp Gaming", dataField: "pdpgaming"},
  {label: "Kith US", dataField: "kith"},
  {label: "ON Running", dataField: "on_running_shopify"},
    {label: "Kith CA", dataField: "kithca"},
  {label: "Kith EU Filtered (Does not go to Shopify Filtered)", dataField: "kitheu"},
  {label: "Kith EU Unfiltered", dataField: "kitheuunfiltered"},
  {label: "Palace", dataField: "palace"},
  {label: "SHOEBACCA", dataField: "shoeBacca"},
  {label: "Travis", dataField: "travis"},
  {label: "Password", dataField: "pw"},
  {label: "Cactus Plant Flea Market", dataField: "cactusplantfleamarket"},
  {label: "DTLR", dataField: "dtlr"},
  {
    label: "DTLR Extra Webhook IMP: (Please set a different hook in the same channel)",
    dataField: "dtlr2"
  },
  {label: "Slam Jam Release Calendar", dataField: "sjs_launches"},
  {label: "Kaws", dataField: "kaws"},
  {label: "Timex", dataField: "timex"},
   {label: "Stussy KR", dataField: "stussykr"},
 {label: "Taylor Swift Shop", dataField: "taylorswift"},
 {label: "Fuck The Population", dataField: "ftp_hook"},
 {label: "ChiikawaMarket", dataField: "chiikawamarket"},
 {label: "Cold Play AU", dataField: "coldplayau"},
 {label: "Taylor Swift Shop UK", dataField: "taylorswiftuk"},
 {label: "Taylor Swift Shop AU", dataField: "taylorswiftau"},
  {label: "Shoepalace", dataField: "shoepalace"},
  { label: "Gallery Canary Yellow", dataField: "canary" },

  {label: "Pesoclo", dataField: "pesoclo"},
     {label: "Tyson Beck", dataField: "tyson"},
  {label: "Eric Emanuel", dataField: "eemanuel"},
  {
    label: "Eric Emanuel Extra Webhook IMP: (Please set a different hook in the same channel)",
    dataField: "eemanuel2"
  },

];

export default shopifyFields;
