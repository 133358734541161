const nikeFields = [
    
   { label: "Nike Refurbished", dataField: "nike_refurbished_us" },
   { label: "Nike Sales Codes", dataField: "nike_sales_codes_us" },

  { label: "Nike Stock US/EU/CA/AU/NZ/NO/KR/JP", dataField: "nikestock" },
  { label: "Nike Stock US", dataField: "nikestockus" },
  { label: "Nike Stock EU", dataField: "nikestockeu" },
  { label: "Nike Stock MX", dataField: "nikestockmx" },
  { label: "Nike Stock KR", dataField: "nikestockkr" },
  { label: "Nike Stock JP", dataField: "nikestockjp" },
  { label: "Nike Stock SG/TW/PH/TH/MY", dataField: "nikestocksg" },

  { label: "SNKRS Launch US", dataField: "nikeus" },
  { label: "Nike.com New US", dataField: "nikefrontendnewus" },
  { label: "Restocks (ACTIVE+CLOSEOUT) US", dataField: "nikerestock" },
  { label: "Restocks (HOLD+INACTIVE) US", dataField: "nikeus_hold" },
  { label: "Restocks (EXCLUSIVE ACCESS) US", dataField: "nikeus_special" },
  { label: "SNKRS Launch GB", dataField: "nikegb" },
  { label: "Nike.com New GB/EU", dataField: "nikefrontendneweu" },
  { label: "Restocks (ACTIVE+CLOSEOUT) EU", dataField: "nikerestockgb" },
  { label: "Restocks (HOLD+INACTIVE) EU", dataField: "nikegb_hold" },
  { label: "Restocks (EXCLUSIVE ACCESS) EU", dataField: "nikegb_special" },
  { label: "SNKRS Launch CA", dataField: "nikeca" },
  { label: "Nike.com New CA", dataField: "nikefrontendnewca" },
  { label: "Restocks (ACTIVE+CLOSEOUT) CA", dataField: "nikerestockca" },
  { label: "Restocks (HOLD+INACTIVE) CA", dataField: "nikeca_hold" },
  { label: "Restocks (EXCLUSIVE ACCESS) CA", dataField: "nikeca_special" },
  { label: "SNKRS Launch JP", dataField: "nikejp" },
  { label: "Nike.com New JP", dataField: "nikefrontendnewjp" },
  { label: "Restocks (ACTIVE+CLOSEOUT) JP", dataField: "nikerestockjp" },
  { label: "Restocks (HOLD+INACTIVE) JP", dataField: "nikejp_hold" },
  { label: "Restocks (EXCLUSIVE ACCESS) JP", dataField: "nikejp_special" },
  { label: "SNKRS Launch CN", dataField: "nikecn" },
  { label: "Nike.com New CN", dataField: "nikefrontendnewcn" },
  { label: "Restocks (ACTIVE+CLOSEOUT) CN", dataField: "nikerestockcn" },
  { label: "Restocks (HOLD+INACTIVE) CN", dataField: "nikecn_hold" },
  { label: "Restocks (EXCLUSIVE ACCESS) CN", dataField: "nikecn_special" },
  { label: "SNKRS Launch SG", dataField: "nikesg" },
  { label: "Nike.com New SG", dataField: "nikefrontendnewsg" },
  { label: "Restocks (ACTIVE+CLOSEOUT) SG", dataField: "nikerestocksg" },
  { label: "Restocks (HOLD+INACTIVE) SG", dataField: "nikesg_hold" },
  { label: "Restocks (EXCLUSIVE ACCESS) SG", dataField: "nikesg_special" },
  { label: "SNKRS Launch DE", dataField: "nikede" },
  { label: "SNKRS Launch FR", dataField: "nikefr" },
  { label: "SNKRS Launch MY", dataField: "nikemy" },
  { label: "SNKRS Launch NL", dataField: "nikenl" },
  { label: "SNKRS Launch IT", dataField: "nikeit" },
  { label: "SNKRS Launch AU", dataField: "nikeau" },
  { label: "Nike.com New AU", dataField: "nikefrontendnewau" },
  { label: "Restocks (ACTIVE+CLOSEOUT) AU", dataField: "nikerestockau" },
  { label: "Restocks (HOLD+INACTIVE) AU", dataField: "nikeau_hold" },
  { label: "Restocks (EXCLUSIVE ACCESS) AU", dataField: "nikeau_special" },
  { label: "Nike.com New MY", dataField: "nikefrontendnewmy" },
  { label: "Restocks (ACTIVE+CLOSEOUT) MY", dataField: "nikerestockmy" },
  { label: "Restocks (HOLD+INACTIVE) MY", dataField: "nikemy_hold" },

  { label: "SNKRS Launch KR", dataField: "nikekr" },
  { label: "Nike.com New KR", dataField: "nikefrontendnewkr" },
  { label: "Restocks (ACTIVE+CLOSEOUT) KR", dataField: "nikerestockkr" },
  { label: "Restocks (HOLD+INACTIVE) KR", dataField: "nikekr_hold" },
  { label: "Restocks (EXCLUSIVE ACCESS) KR", dataField: "nikekr_special" },
  { label: "SNKRS Launch PL", dataField: "nikepl" },
  { label: "SNKRS Launch BE", dataField: "nikebe" },
  { label: "SNKRS Launch MX", dataField: "nikemx" },
  { label: "Nike.com New MX", dataField: "nikefrontendnewmx" },
  { label: "Restocks (ACTIVE+CLOSEOUT) MX", dataField: "nikerestockmx" },
  { label: "Restocks (HOLD+INACTIVE) MX", dataField: "nikemx_hold" },
  { label: "Restocks (EXCLUSIVE ACCESS) MX", dataField: "nikemx_special" },
  { label: "SNKRS Launch GR", dataField: "nikegr" },
  { label: "SNKRS Launch PT", dataField: "nikept" },
  { label: "SNKRS Launch PH", dataField: "nikeph" },
  { label: "Nike.com New PH", dataField: "nikefrontendnewph" },
  { label: "Restocks (ACTIVE+CLOSEOUT) PH", dataField: "nikerestockph" },
  { label: "Restocks (HOLD+INACTIVE) PH", dataField: "nikeph_hold" },
  { label: "Restocks (EXCLUSIVE ACCESS) PH", dataField: "nikeph_special" },
  { label: "SNKRS Launch TH", dataField: "niketh" },
  { label: "Nike.com New TH", dataField: "nikefrontendnewth" },
  { label: "Restocks (ACTIVE+CLOSEOUT) TH", dataField: "nikerestockth" },
  { label: "Restocks (HOLD+INACTIVE) TH", dataField: "niketh_hold" },
  { label: "Restocks (EXCLUSIVE ACCESS) TH", dataField: "niketh_special" },
  { label: "Instore US", dataField: "nikeinstoreus" },
  { label: "Instore Florida", dataField: "nikeinstorefl" },
  { label: "Instore EU", dataField: "nikeinstoreeu" },
  { label: "Instore SG", dataField: "nikeinstoresg" },
  { label: "Instore MY", dataField: "nikeinstoremy" },
  { label: "Instore TH", dataField: "nikeinstoreth" },
  { label: "Instore MX", dataField: "nikeinstoremx" },
  { label: "Nike Instore AU Restocks", dataField: "nikeinstoreau" },
  { label: "Nike Instore AU Sales", dataField: "nikeinstoreau_sales" },
  { label: "Instore TH New", dataField: "nikeinstoreth_new" },
      { label: "Instore JP", dataField: "nikeinstoreasia" }

];

export default nikeFields;
