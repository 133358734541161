const socialFields = [
  {label: "Email Monitor", dataField: "email_monitor"},
  {label: "Social Media Filtered", dataField: "socialfiltered"},
  {label: "Instagram Stories", dataField: "instagramstories"},
  {label: "Instagram", dataField: "instagram"},
  {label: "Facebook", dataField: "facebook"},
  {label: "Twitter", dataField: "twitter"},
  {label: "JYW Pokeca Twitter", dataField: "jyw_pokeca_twitter"},
  {label: "Stock News Twitter", dataField: "stock_twitter"},
  {label: "TcgSpy/pokemondeals_au Twitter", dataField: "pokemondeals_au_twitter"},
];

export default socialFields;
